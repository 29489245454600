import React from "react";
import Map from "./Map";
import blogImage from "../images/img-tree-plantation.jpg";
const EventsDetailTree = () => {
  return (
    <section className="event-detail-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="blog-content">
              <div className="blog-item">
                <div className="blog-img">
                  <img src={blogImage} alt="" />
                  <span className="blog__tag">
                    <span className="date__num-text">9</span>
                    <span className="date__mon-text">mar</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="event-detail-content">
              <div className="event-detail-item">
                <h3 className="event__title">Tree Plantation</h3>
                <p className="event__text">
                  We, Purba Dhansara Jote Jana kalyan Samity (NGO) on
                  Environment and Plantation Programme in Siliguri area, W.B.
                  Our association carries out tree planting program from 5th
                  June in every year keeping in mind the pollution free
                  environment. We collect saplings through letters at different
                  forest offices and plant them in different schools, colleges,
                  clubs, temples etc. We also try to make the common people
                  aware about planting trees.
                </p>
                <p className="event__text">
                  Trees are key pillars of the world for both humans and the
                  environment. They benefit us by purifying water, air and
                  crating better social conditions. They benefit the environment
                  by providing homes for various forms of life, cooling our
                  climate and improving our soil. Our mission is to reforest the
                  world.
                </p>
              </div>
              <div className="event-detail-item">
                <h3 className="event__title event__title2">
                  You can help us make your community greener by planting a
                  tree.
                </h3>
                <p className="event__text">
                  We inspire and empower organisations, Government, Communities
                  and individuals with the knowledge and tools to create
                  positive, lasting change at a national and local level.
                  Environmental awerness, conservation and protecting our
                  surroundings are an integrate part of Purba Dhansara Jote Jana
                  Kalyan Samity (NGO). Fighting climate change is not easy, and
                  no one can do it alone. That is why we try to make it easier
                  for everyone to plant and nurture trees around them. You and
                  help out right now.
                </p>
              </div>
              <div className="event-detail-btn">
                <a href="#none" className="theme-btn">
                  register now
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="event-detail-sidebar">
              <div className="event-detail-item">
                <h3 className="event__title">Event Details</h3>
                <ul className="event__list">
                  <li>
                    <span>Starting Time:</span> 8:00AM to 2:00PM
                  </li>
                  <li>
                    <span>Date:</span>9 March, 2019
                  </li>
                  <li>
                    <span>Category:</span>Health
                  </li>
                  <li>
                    <span>Phone:</span>+91-9933086710
                  </li>
                  <li>
                    <span>Website:</span>info@pdjjks.org
                  </li>
                  <li>
                    <span>Location:</span>Siliguri, West Bengal, India
                  </li>
                </ul>
              </div>
              <div className="event-detail-item event-detail-item2">
                <Map />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EventsDetailTree;
