import React from "react";

const Map = () => {
  return (
    <div>
      <section className="map-area">
        <div id="map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3565.30174419398!2d88.36482450000001!3d26.6708304!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39e44500eb9c44eb%3A0x542f9e0a023e595!2sPURBA%20DHANSARA%20JOTE%20JANAKALYAN%20SAMITY!5e0!3m2!1sen!2sin!4v1688218162409!5m2!1sen!2sin"
            className="map"
            title="googlemap"
            allowFullScreen=""
          ></iframe>
        </div>
      </section>
    </div>
  );
};

export default Map;